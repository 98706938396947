import * as wpModel		from "@geotoura/shared/wpModel";
import * as commonModel	from "@geotoura/shared/commonModel";

export type ScreenSize = "desktop"|"tablet"|"mobile";

export type Model	= {
	app:		AppModel|null,
	menu:		boolean,
	canHover:	boolean,
	screenSize:	ScreenSize,
};

export namespace Model {
	export const initial = (screenSize:ScreenSize, canHover:boolean):Model => ({
		app:	null,
		menu:	false,
		canHover,
		screenSize,
	});
}

export type AppModel = {
	data:					wpModel.NavData,
	selectedTerritoryId:	commonModel.TerritoryId|null,
};
